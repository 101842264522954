import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NotFoundPage = () => (
  <Layout>
    <div className='strip strip-blog strip-blog-page'>
      <div className='container'>
        <span className='section-title mb-3'>Error 404</span>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-8'>
            <em>The page you requested can not be found.</em>
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export const Head = () => <SEO title='Page not found' />;

export default NotFoundPage;
